import './index.scss';
import "@fancyapps/ui/dist/fancybox.css";
// import $ from "jquery";

/*import bvi from 'bvi';*/

import Slider from "./modules/Slider";
import DefaultUI from "./modules/DefaultUI";

// слайдер новостей на главной
new Slider("#main-slider");

// новости
new Slider(".news_articles", {center: false, infinite: false}, true);

// о компании
new Slider("#about-slider", {center: false});

// о компании
new Slider(".about-slider", {center: false});

// партнеры
new Slider("#partners-slider", {center: false});

// анонсы
new Slider(".main-slider_announcements_items", {center: false}, true, 760);

// галлерея
new Slider("#gallery-slider", {center: false});

// UI
DefaultUI.spoiler();
DefaultUI.copyUI();
DefaultUI.mobileMenu();
DefaultUI.versionForVisuallyImpaired();