import Cookies from 'js-cookie';

class DefaultUI {

    static isVersionForVisuallyImpaired = false;

    /**
     * Спойлеры
     */
    static spoiler() {
        let spoilers = document.querySelectorAll('.spoiler_header');
        for(let i = 0; i < spoilers.length; i++) {
            spoilers[i].addEventListener('click', () => {
                spoilers[i].nextElementSibling.classList.toggle('spoiler_content--active');
            });
        }
    }

    /**
     * Копировать UI
     */
    static copyUI() {
        let copyUIBtn = document.querySelectorAll('.default-ui_header');
        for(let i = 0; i < copyUIBtn.length; i++) {
            copyUIBtn[i].addEventListener('click', () => {
                window.navigator.clipboard.writeText(copyUIBtn[i].nextElementSibling.innerHTML.toString())
                    .then(() => {
                        console.log('copy')
                    })
                    .catch(err => {
                        console.log('Something went wrong', err);
                    });
            });
        }
    }

    /**
     * Мобильное меню
     */
    static mobileMenu() {
        let menuWrapper = document.getElementById('menu-wrapper');
        let menuBTN = document.getElementById('toggle-menu-btn');
        menuBTN.addEventListener('click', () => {
            menuWrapper.classList.toggle('header_menu--opened')
        });
    }

    /**
     * Версия для слабовидящих
     */
    static versionForVisuallyImpaired() {
        let cookiesName = 'diz_name';
        let className = 'isVersionForVisuallyImpaired';
        let btnEye = document.getElementById('versionForVisuallyImpaired');

        if(Cookies.get(cookiesName)) {
            document.querySelector('body').classList.add(className);
        }

        btnEye.addEventListener('click', () => {
            if(Cookies.get(cookiesName)) {
                Cookies.remove(cookiesName);
            }
            else {
                Cookies.set(cookiesName, true, {expires: 10 * 24 * 3600, autoencode : 0});
            }
            document.querySelector('body').classList.toggle(className);
        });
    }
}

export default DefaultUI;